import { BsCreditCard, BsTruck } from 'react-icons/bs';
import useDeviceType from '@/hooks/useDeviceType';
import { Divider, Icon, Text } from '@/components/atoms';
import styles from './servicesSlider.module.scss';
import { Slider, ServicesBadge } from '@/components/molecules';
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

const ServicesSlider = () => {
  const { isDesktop } = useDeviceType();
  const ribbonBenefits = [
    {
      id: '0',
      title: 'Envío Gratis',
      label: 'Envíos gratis en compras desde $10.000',
      icon: (
        <BsTruck
          color="var(--color-primary)"
          size={50}
        />
      )
    },
    {
      id: '1',
      title: 'Compra en cuotas',
      label:
        'Pagá con débito, MODO o en cuotas sin interés con tarjetas bancarizadas.',
      icon: (
        <BsCreditCard
          color="var(--color-primary)"
          size={50}
        />
      )
    },
    {
      id: '2',
      title: 'Primer cambio gratis',
      label: 'Tu primer cambio gratis a domicilio',
      icon: (
        <Icon
          type="change"
          colored
          width="50px"
        />
      )
    },
    {
      id: '3',
      title: 'Compra Protegida',
      label: 'Protegemos los datos de nuestros clientes en todo momento',
      icon: (
        <Icon
          type="security"
          colored
          width="50px"
        />
      )
    }
  ];
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <Text
          textColor="white"
          textSize="xl"
          weight="bolder"
          className={styles.text}
        >
          Nuestros Servicios
        </Text>
        <Divider
          color="var(--color-white)"
          style={{ margin: 'auto' }}
        />
      </div>
      {!isDesktop ? (
        <Slider
          withoutChevrons
          withDots
        >
          {ribbonBenefits.map((r) => (
            <ServicesBadge
              title={r.title}
              label={r.label}
              key={r.id}
              icon={r.icon}
            />
          ))}
        </Slider>
      ) : (
        <div className={styles.desktop}>
          {ribbonBenefits.map((r) => (
            <ServicesBadge
              title={r.title}
              label={r.label}
              key={r.id}
              icon={r.icon}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ServicesSlider;
