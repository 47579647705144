import PropTypes from 'prop-types';
import { Image } from '@/components/atoms';
import useDeviceType from '@/hooks/useDeviceType';
import styles from './installmentsCard.module.scss';

const InstallmentsCard = ({ banner }) => {
  const { isDesktop } = useDeviceType();
  return (
    <div className={styles.container}>
      <Image
        imageStyles={{
          width: '100%',
          height: '100%',
          objectFit: 'cover'
        }}
        src={isDesktop ? banner?.desktop_image?.url : banner?.mobile_image?.url}
        alt={`banner${banner?.alt_text}`}
        heightSkeleton={isDesktop ? '261.1px' : '400px'}
      />
    </div>
  );
};

InstallmentsCard.propTypes = {
  banner: PropTypes.object.isRequired
};

export default InstallmentsCard;
